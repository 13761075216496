<template lang="pug">
  v-container
    v-row(justify="center")
      v-col(cols="12" lg="3" md="3")
        v-card
          v-toolbar(
            color="primary"
            dense
            dark
            flat
          )
            span Create
          v-container
            create-candled-rack-eggs(
              ref="createCandedRackEggs"
              :default-data.sync="editCandledRackEggs"
            )
      v-col(cols="12" lg="6" md="6")
        v-card
          v-toolbar(
            color="primary"
            dense
            dark
            flat
          )
            span List
          v-container
            table-candled-rack-eggs(
              @updated="$refs.createCandedRackEggs.getIncubatorIds()"
              @edit="item => editCandledRackEggs = item"
            )
</template>
<script>
export default {
  name: 'CandledRackEggs',
  components: {
    createCandledRackEggs: () => import('./Create'),
    tableCandledRackEggs: () => import('./Table'),
  },
  data () {
    return {
      editCandledRackEggs: {},
    }
  },
}
</script>